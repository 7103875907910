// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.container {
    padding: 3em !important;
}
#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 1;
    background-color: #f3d01e;
    color: #024f6e;
}
.icon-location-url {
    color: white;
    text-decoration: none;
    transition: 0.1s linear all;

    &:hover {
        color: orange;
    }
}

//Buttons
.blue-small-btn{
    background-color: #e0edfc;
    color: #024f6e;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 1.8em 1em;
    padding: .8em 2em;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    text-decoration: none;
    display: block;
    -moz-transition: background-color .3s ease-in;
    -o-transition: background-color .3s ease-in;
    -webkit-transition: background-color .3s ease-in;
}
.blue-small-btn:hover {
    background-color: #015b7e;
    color: #f3d01e;
    -moz-transition: background-color .1s ease-in;
    -o-transition: background-color .1s ease-in;
    -webkit-transition: background-color .1s ease-in;

}
.blue-light-small-btn {
    border-radius: 20px;
    color: #024f6e;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 1em;
    padding: .8em 2em;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    text-decoration: none;
    display: block;
    -moz-transition: background-color .3s ease-in;
    -o-transition: background-color .3s ease-in;
    -webkit-transition: background-color .3s ease-in;
}

.blue-light-small-btn:hover {
    background-color: #ffffff;
    color: #024f6e;
    -moz-transition: background-color .1s ease-in;
    -o-transition: background-color .1s ease-in;
    -webkit-transition: background-color .1s ease-in;

}
.yellow-btn {
    background-color: #f0e051;
    border-radius: 20px;
    color: #024f6e;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 1.8em 1em;
    padding: .8em 2em;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    text-decoration: none;
    display: block;
    -moz-transition: background-color .3s ease-in;
    -o-transition: background-color .3s ease-in;
    -webkit-transition: background-color .3s ease-in;
}

.yellow-btn:hover {
    background-color: #f3d01e;
    color: #024f6e;
    -moz-transition: background-color .3s ease-in;
    -o-transition: background-color .3s ease-in;
    -webkit-transition: background-color .3s ease-in;

}
.yellow2-btn {
    background-color: #f0e051;
    border-radius: 20px;
    color: #024f6e;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 1em;
    padding: .8em 2em;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    text-decoration: none;
    display: block;
    -moz-transition: background-color .3s ease-in;
    -o-transition: background-color .3s ease-in;
    -webkit-transition: background-color .3s ease-in;
}

.yellow2-btn:hover {
    background-color: #015b7e;
    color: #f3d01e;
    -moz-transition: background-color .1s ease-in;
    -o-transition: background-color .1s ease-in;
    -webkit-transition: background-color .1s ease-in;
}

//Highlights
.highlight1 {
    color: #bedaff;
    display: block;
    font-size: 20px;
    font-weight: bold;
}

.highlight-yellow {
    color: #f0e051;
    display: block;
    font-weight: bold;
}
.highlight-darkblue {
    color: #01374c;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    display: block;
}
.highlight-yellow-block{
    color: #01374c;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    display: block;
    border-left: solid 8px #f3d01e ;
    margin-left: .5em;
    padding-left: .5em;
    margin-bottom: 1em;
}
.highlight-white {
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    display: block;
}
.subtitle2{
    color: #015b7e;
    display: block;
    font-size: 1.5em;
}

//Links
.link-yellow{
    position: relative;
    text-decoration: none;
    color: #015b7e;
    font-weight: bold;
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;
}
.link-yellow:hover{
    color: #6ebcdb;
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;
}
.link-yellow::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #f0e051;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.link-yellow:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

//Home
.menu{
    margin: 1.8em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu a{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.55);
    text-align: center;
    font-size: 1em;
    -moz-transition: color .3s ease-in;
    -o-transition: color .3s ease-in;
    -webkit-transition: color .3s ease-in;
}
.menu a:hover {

    color: #024f6e;
    -moz-transition: color .3s ease-in;
    -o-transition: color .3s ease-in;
    -webkit-transition: color .3s ease-in;

}
.nav-menu2 {
    display: block;
    width: 100%;
    height: auto;
    background-color: #e0edfc !important;
}
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;

        li{
            display: inline-block;
        }
    }
.menu-logo-booking{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height:auto;
    background-color: rgb(253, 253, 253);
}
.nav-item{
    display: inline-block;
    width: auto;
}
.booking{
    display: inline-block;
    text-align: right;
    height: auto;
}
#responsive-menu {
    display: none;
    width: 100%;
    padding: 1em;
    height: auto;
    border-top: 1px solid #e4e4e4;
}
.hamb-button{
    text-align: right;
    padding: 0;

    button{
        border: 0;
        height: 100%;
        font-size: 1.5em;
        color: #003449;
        background-color: #fff;
    }
}
#logo{
    display: flex;
    align-items: center;
}
.logo{
    height: auto;
    text-decoration: none;
    color: #01374c;
    font-size: 1.3em;
    font-weight: bold; 
    margin-left:1em;
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;
}
.logo:hover{
    color: #0073a0;
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;

}
.logo2:hover {
    color: #0073a0;
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;

}
.banner-home{
    background: url("../images/home-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    color: #fff;
}
.banner-title{
    align-items: center;
    display: flex;
    padding: 10em 2em;
    height: auto;
    justify-content: center;
    width: 100%;
}
h1{
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
    height: auto;
    font-size: 2.5em;
    width: 100%;
    font-weight: bold;
    letter-spacing: 5px;
    text-align: center;
    margin: 0;
}
.banner-title-text p {
    font-size: 2em;
    color: #f8fbff;
}
.banner-schedule {
    width: 100%;
    height: auto;
    display: flex;
    color: #ffffff;
    padding-bottom: 3em;
    
    span {
        text-transform: uppercase;
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;
        letter-spacing: 3px;
    }

    ul{
        list-style-position: inside;
        padding: 0;
    }
    i{
        display: block;
        font-size: 2em;
        margin-bottom: 15px;
    }
}
.contact-bar{
    color: #003449;
    font-weight: bold;
    padding: .5em;
    text-align: center;

}
.icon {
    text-align: center;
    font-size: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 auto 20px auto;
}
.blue-square{
    background-color: #f8fbff;
    color: #015b7e;
    padding: 5em;
    text-align: center;
}

.blue-square:last-child {
    background-color: #e0edfc;
}
.home-services {
    padding: 3em 5em;
    background-color: #fff;
    color: #015b7e;


    span {
        display: block;
        width: 100%;
        height: auto;
        font-size: 2em;
        color: #01374c;
        text-align: center;
        margin: 1em 0 2em 0;
    }
    ul {
        list-style-type: none;
        color: #015b7e;
        font-size: 1.2em;
        
    }
    ul li:before {
        content: '';
        height: 20px;
        width: 20px;
        display: inline-block;
        background-image: url("../images/yellow-star.png");
        background-size: 20px;
        background-repeat: no-repeat;
        margin-right: 10px;
        vertical-align: middle;
    }
    ul li{
        margin-bottom: 1em;
        display: block;
    }
    ul li a {
        color: #015b7e;
        text-decoration: none;
        vertical-align: middle;
    }
}
.home-feedback{
    text-align: center;
    padding: 2em;
    background-color: #fff;
    color: #015b7e;

    span {
        font-size: 1.5em;
        color: #01374c;
    }
}
.home-about-us{
    text-align: center;
    width: 100%;
    height: auto;
    padding: 11em;
    background-color: #003449;
    color: rgb(247, 247, 247);
    background: url("../images/about-us-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    a{
        border:none;
        margin: 3em auto;
    }
}
//About us
.banner-about-us {
    background: url("../images/about-us-section.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    height: auto;
    color: #fff;
}

.text-2blocks{
    padding: 0 3em;
    color: #71848c;

    span{
        display: block;
        width: 100%;
        height: auto;
        margin: 1em 0;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-weight: bold;
        color: #9fd2e7;
    }
    b{
        color: #015b7e;
    }
}
.banner-mision-vision-values {
    width: 100%;
    height: auto;

    span {
        text-transform: uppercase;
        color: #f0e051;
        font-size: 1.2em;
        font-weight: bold;
        letter-spacing: 3px;
        display: block;
    }

    ul {
        list-style-position: inside;
        padding: 0;
        display: block;
        width: 100%;
        height: auto;
        padding: 0 2em 2em 2em;
        list-style: circle;
    }
    li{
        color: #f8fbff;
        list-style: circle;
        display: block;
    }
  
}
// Our Services
.banner-our-services {
    background: url("../images/our-services-section.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    height: auto;
    color: #fff;
    padding: 4em 2em;
}

.block-70{
    display: inline-block;
    width: 70%;
    height: auto;
    vertical-align: top;
    padding: 3em;
}
.block-30 {
    display: inline-block;
    width: 30%;
    height: auto;
    vertical-align: top;
    padding: 3em;
    color: #71848c;

    ul{
        list-style: none;
        padding: 0;
    }
    li{
        border-top:solid 2px #f3d01e;
        padding:1em 0;
        width: 100%;

        span{
            display: block;
            width: 100%;
            font-weight: bolder;
            font-size: 1.2em;
            letter-spacing: 2px;
            color: #015b7e;
        }
        a{
            font-weight: bold;
            text-decoration: none;
            color: #6ebcdb;
        }
    }
}
.accordion-body{
    background-color: #fff;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-out;

    p {
        padding: 1em 0;
    }
}
// Our Team
.banner-our-team {
    background: url("../images/our-team-section.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    height: auto;
    color: #fff;
    padding: 4em 2em;
}

.tag-title {
     font-style: normal;
     font-weight: bold;
     display: block;
     width: 100%;
     height: auto;
     margin-top: 1.5em;
     font-size: 1.3em;
     color: #015b7e;
 }
 .tag-subtitle{
    font-style: italic;
    font-size: .8em;
    color: #7ec6e2;
 }
 .tag-text {
     color: #71848c;
     margin: 1em 0 0 0;
 }
.img-doctors{
    width: 130px;
    height: 130px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #eee;
}
.nurses-section {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 5em 2em;
}

.tag-nurses {
    padding: 1em;
    height: auto;
    text-align: center;
    font-style: italic;
    font-size: .8em;
    color: #7ec6e2;

    span {
        font-style: normal;
        font-weight: bold;
        display: block;
        width: 100%;
        height: auto;
        margin-top: 1em;
        font-size: 1.3em;
        color: #015b7e;
    }

    a {
        display: block;
        margin: 1em 0;
        color: #c4c4c4;
        text-decoration: none;
        font-style: normal;
    }
}
.close{
    display: block;
    text-align: right;
    width: 100%;
    height: auto;
    font-weight: bold;
    margin-top: 1em;
    font-size: 1.5em;
    color: #c4c4c4;
    vertical-align: top;
}  
.read-more-ourteam{
    display: block;
     margin: 1em auto;
    color: #c4c4c4;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-style: normal;
    border: none;
    background-color: #fff;
}

.img-nurses {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #eee;
}
.recept-section {
    width: 100%;
    height: auto;
    padding: 5em 2em 5em;
}

.tag-recept {
    padding: 1em;
    height: auto;
    text-align: center;
    font-style: italic;
    font-size: .8em;
    color: #7ec6e2;

    span {
        font-style: normal;
        font-weight: bold;
        display: block;
        width: 100%;
        height: auto;
        margin-top: 1em;
        font-size: 1.3em;
        color: #015b7e;
    }

    a {
        display: block;
        margin: 1em 0;
        color: #c4c4c4;
        text-decoration: none;
        font-style: normal;
    }
}

.img-recept {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #eee;
}
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.content-popup {
    position: absolute;
    text-align: left;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 3em 3em 3em;
    height: auto;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.card{
    width: 18rem;
    margin:0 auto;
}
//Contact us
.banner-generic {
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    padding: 4em 2em;
}
.form{
    input {
        width: 100%;
        display: block;
        padding: 8px 10px;
        margin: 1em 0;
        border: 2px solid #bfe6e8;
        border-radius: 8px;
        color: #8699a0;
    }
    input:last-child {
        width: 100%;
        display: block;
        padding: 1em;
        margin: 1em 0;
        border: none;
        border-radius: 8px;
        color: #024f6e;
    }

    textarea {
        width: 100%;
        display: block;
        padding: 8px 10px;
        margin: 1em 0;
        border: 2px solid #bfe6e8;
        border-radius: 8px;
        color: #8699a0;
    }
}
.directions{
    text-align: center;
    padding:2em 1em;
    width: 100%;
    height: auto;
}
.directions-map span{
    width: 100%;
    text-align: left;
    display: block;
    color: #6ebcdb;
    font-weight: bold;
    font-size: 1.4em;
    padding-top: 1em;
}
// Footer
.footer {
    background-color: #01374c;
    color: #a1b1c3;
    padding: 2em;
    text-align: left;
}

.footer .col:last-child {
    text-align: right;
}



//css that appeared on the browser but idk where it comes, but it's getting on my way

.py-4{
    padding: 0 !important;
}

@media screen and (max-width:1290px) {
    .menu {
        margin: 1.8em .5em;
    }
    .menu a {
        font-size: .9em;
        letter-spacing: 0px;
    }
        
    .block-70{
        padding: 3em 2.5em 3em 3em;
    }
    .block-30 {
        padding: 3em 2em 3em 0;
    }
    .blue-small-btn {
        font-size: 10px;
        letter-spacing: 1px;
        margin: 1.8em .5em;
        padding: .8em 2em;
    }
    .blue-light-small-btn {
        font-size: 10.5px;
        font-weight: bold;
        letter-spacing: 2px;
        margin: 1em 0;
        padding: .8em 1em;
    }
    .yellow2-btn {
        font-size: 10.5px;
        letter-spacing: 2px;
        margin: 1em 0;
        padding: .8em 1em;
    }
    .banner-home {
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        background-attachment: inherit;
    }
}

@media screen and (max-width:900px) {
    .block-70{
        width: 100%;
        display: block;    
    }
    .block-30{
        width: 100%;
        display: block;   
        padding: 3em;     
    }
    #menu2{
        display: none;
    }
    #responsive-menu{
        display: flex;
        justify-content: space-around;
    }
    #logo{
        display: none;
    }
    .nav-item {
         display: inline-block;
         width: 50%;
    }
    .logo2{
        height: auto;
        text-decoration: none;
        color: #01374c;
        font-size: 1.3em;
        font-weight: bold;
    }
    
    .home-about-us {
        padding: 10em 5em;
    }
    .blue-light-small-btn{
        letter-spacing: 0;
    }
    .yellow2-btn {
        letter-spacing: 0;
    }
    .banner-title {
        padding: 5em 2em 2em 2em;
    }
    
    
}
@media screen and (max-width:700px) {
    h1 {
        font-size: 2em;
        letter-spacing: 0;
    }
    #responsive-menu {
        padding: 1em .5em;
    }
    
    .highlight-yellow-block {
        font-size: 1.4em;
     }
     .highlight-darkblue{
        font-size: 1.4em;
     }
    .blue-light-small-btn {
        letter-spacing: 2px;
    }
    
    .yellow2-btn {
        letter-spacing: 2px;
    }
    

}
@media screen and (max-width:576px) {
    .logo2 {
        font-size: 1em;
    }
    .hamb-button button{
        font-size: 1.1em;
    }
    .banner-schedule span{
        font-size: 1em;
    }
    .home-services {
        padding: 3em;
    }
    .block-70{
        padding: 2em 1.5em;
    }
    .block-30 {
        padding: 2em 1.5em;
    }
    .text-2blocks {
        padding: 0 1em;
    }
}